import { combineReducers } from "redux";

import app from "./app";
import language from "./language";

const rootReducer = combineReducers({
  app,
  language,
});

export default rootReducer;
