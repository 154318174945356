import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import imagesCooking from "../../../assets/pages/alternatives-cooking.jpg";
import imagesPainting from "../../../assets/pages/alternatives-painting.jpg";
import imagesReading from "../../../assets/pages/alternatives-reading.jpg";

const Alternatives = () => (
  <>
    <Helmet>
      <title>Alternatives</title>
    </Helmet>
    <PageHeader title="Alternatives" />
    <p>
      Instead of using your time to flick through social media, you could be productive and learn a new language, or start a new hobby. Learning a new language for just 10 minutes a day could improve your linguistic ability considerably. Going outside for prolonged periods of time instead of staying indoors can help spark a new interest as well as boosting your mental health, and making you feel good, while also getting exercise.
    </p>
    <h2>Some things that could be done instead of watching social media</h2>
    <ul>
      <li>
        <h3>Reading</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            When was the last time you read a full book that interested you? Shops like Waterstones and the Hastings bookshops are filled with a range of books.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Reading"
              src={imagesReading}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>Making a new food recipe</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Learning how to improve your cooking skills is an extremely important life skill that can grow your independence and gives you the ability to nourish yourself.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Cooking"
              src={imagesCooking}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>Going for a run</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Going outside in general can be beneficial towards your physical and mental health, and gives you a break from the constant online world. Going for a run and having a set time each day or week can prove to have antidepressant effects.
          </div>
        </div>
      </li>
      <li>
        <h3>Learning a new language or attending martial arts</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Not knowing a language can be a huge communicative boundary when talking to someone, but by working on your vocabulary skills a small amount per day, you can become fluent especially with the help of software like Duolingo or Drops, which is a language learning game. Also, dedicating some time for martial arts can help increase your mindfulness, awareness, and can help you feel more focused. Doing a martial art can help reduce anxiety and depression, which is a leading consequence that occurs with the overuse of social media. Martial arts can also help you socialise.
          </div>
        </div>
      </li>
      <li>
        <h3>Learning how to dance or improve your artistic skills</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Dancing can help you socialise more, while improving your balance and posture. It can also keep your mind sharp and reduce stress. Improving your artistic skills can increase your hand to eye coordination and bring out your creative side.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Painting"
              src={imagesPainting}
            />
          </div>
        </div>
      </li>
    </ul>
  </>
);

export default Alternatives;
