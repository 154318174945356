import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import image from "../../../assets/pages/effects-of-social-media.jpg";

const EffectsOfSocialMedia = () => (
  <>
    <Helmet>
      <title>Effects of social media</title>
    </Helmet>
    <PageHeader title="Effects of social media" />
    <p>
      Opening up social media and constantly comparing yourself to others can lead you towards feelings of inferiority and can cause self esteem issues. Comparing your life towards someone’s highlights online can cause negative effects to take place.
    </p>
    <p>
      Cyberbullying can also occur, through online harassment which can come out as toxic or mean remarks, that could be meant as a ‘joke’. Offensive comments, threats or misinformation about an individual can harm you mentally.
    </p>
    <p>
      Many social media platforms have filters that are accessible via the app, while these filters can change your appearance subtly, it can affect your mental view of yourself and others in the long run. People who are insecure about their appearance are more likely to use a filter, therefore further solidifying the belief that their appearance is inadequate.
    </p>
    <p>
      Filters can cause body dysmorphia, but also change your natural view on what someone is normally meant to look like. It can create a false sense of normality on how you and others look.
    </p>
    <div className="image-container">
      <img
        src={image} 
        width="100%"
        alt="Effects of social media"
      />
    </div>
  </>
);

export default EffectsOfSocialMedia;
