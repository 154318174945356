import React from "react";

import { Helmet } from "react-helmet-async";

import logos from "../../../assets/logos.png";

import "./index.scss";

const Home = () => (
  <>
    <Helmet>
      <title>Home</title>
    </Helmet>
    <h1>How does social media affect our mental health?</h1>
    <div className="image-container">
      <img
        src={logos} 
        width="100%"
        alt=""
      />
    </div>
  </>
);

export default Home;
