import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import image from "../../../assets/pages/positive-impacts.jpg";

const PositiveImpacts = () => (
  <>
    <Helmet>
      <title>Positive impacts</title>
    </Helmet>
    <PageHeader title="Positive impacts" />
    <p>
      Social media is used worldwide and is widely regarded to be the main source of online usage for kids under 18. 
      Around 60% of the world uses social media, with it flourishing and being more normalised in the younger generations. 
      School children are especially driven and more likely to use social media, to connect with friends outside of school, 
      or to watch videos of other people.
    </p>
    <p>
      According to the research of Chaffey, there are over 4.8 billion people globally using social media, with around 
      150 million new users in the last year, with the daily global average being 2 hours and 24 minutes. There are 
      several benefits to using social media, such as obtaining a sense of belonging, and can strengthen relationships 
      and bonding between an individual or group to another. Social media can provide several ways to find support, 
      and can also give your mood a boost in the short term. However, using social media can increase your awareness, 
      allowing you to speak more passionately about certain subjects.
    </p>
    <div className="image-container">
      <img
        src={image} 
        width="100%"
        alt="Effects of social media"
      />
    </div>
  </>
);

export default PositiveImpacts;
