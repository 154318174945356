export const languageReduxState = {
  language: "en-GB",
};

const language = (state = languageReduxState, action) => {
  switch (action.type) {
    case "SET_LANGUAGE_LANGUAGE": return {
      ...state,
      ...{
        language: action.payload,
      }
    };
    default: return state;
  }
};

export default language;
