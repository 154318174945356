import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import image from "../../../assets/pages/what-is-social-media.jpg";
import logoFacebook from "../../../assets/pages/what-is-social-media-logo-facebook.png";
import logoInstagram from "../../../assets/pages/what-is-social-media-logo-instagram.png";
import logoSnapchat from "../../../assets/pages/what-is-social-media-logo-snapchat.png";
import logoTiktok from "../../../assets/pages/what-is-social-media-logo-tiktok.jpg";
import logoWhatsapp from "../../../assets/pages/what-is-social-media-logo-whatsapp.png";
import logoYoutube from "../../../assets/pages/what-is-social-media-logo-youtube.jpg";

import "./index.scss";

const WhatIsSocialMedia = () => (
  <>
    <Helmet>
      <title>What is social media?</title>
    </Helmet>
    <PageHeader title="What is social media?" />
    <p>
      Social media is an online technology that allows users to share information, 
      ideas and photos/videos. Online communities can be made and is a major part of 
      the connection between our friends and family, as it provides ways to interact 
      and chat to people without the need to be there physically. 
    </p>
    <div className="image-container">
      <img
        src={image} 
        alt="What is social media?"
      />
    </div>
    <p>
      Due to the simple and effective ways social media can link people with, many of the 
      apps are widely used around the world, with billions of people using it globally. 
      More and more companies are using social media to fuel their constant advertisements 
      and campaigns.
    </p>

    <h2>
      The largest social media platforms consist of:
    </h2>
    <ul>
      <li>
        <h3>Snapchat</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            A huge social media platform, having a feature which makes texts and other 
            information inaccessible after a short time. Users have a constant urge to stay connected 
            to the app, to maintain streaks and send snaps, which can create an unhealthy addiction.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Snapchat"
              src={logoSnapchat}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>Instagram</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Instagram is an app capable of sharing videos and photos, while also 
            being able to share them to your followers and friends. Viewers can like and comment 
            on these posts.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Instagram"
              src={logoInstagram}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>Facebook</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            Facebook focuses on a more personalised experience, it allows users to 
            connect with friends, which keeps them updated on their daily activities. While 
            being able to maintain and strengthen relationships, Facebook can also cause FOMO, 
            anxiety, as many people compare other people's highlights to their average day.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="Facebook"
              src={logoFacebook}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>TikTok</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            An app that rose to popularity in 2018, and is more favourable for teens. 
            Users can watch, share or create 15 second videos on their phones, while also having 
            a personalised feed that suits your history.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="TikTok"
              src={logoTiktok}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>WhatsApp</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            WhatsApp allows people to text, call, send their location, send 
            images, voice messages and also being able to call people.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="WhatsApp"
              src={logoWhatsapp}
            />
          </div>
        </div>
      </li>
      <li>
        <h3>YouTube</h3>
        <div className="list-item-container">
          <div className="list-item-container-text">
            A free online website that allows people to post and make videos. 
            It joined the shorts genre and now has YouTube shorts, which is made up of 
            short clips of content.
          </div>
          <div className="list-item-container-image">
            <img 
              alt="YouTube"
              src={logoYoutube}
            />
          </div>
        </div>
      </li>
    </ul>
  </>
);

export default WhatIsSocialMedia;
