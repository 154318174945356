import React from "react";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../actions";

import { Link } from 'react-router-dom';
import { slide as Menu } from "react-burger-menu";

import classNames from "classnames";

import "./index.scss";

const Sidebar = props => {

  const { app } = useSelector(state => state);

  const { appActions } = { ...allActions };
  const dispatch = useDispatch();

  const handleOnClose = () => {
    dispatch(appActions.setAppMenuIsOpen(false));
  };
  const handleOnOpen = () => {
    dispatch(appActions.setAppMenuIsOpen(true));
  };

  return (
    <Menu
      isOpen={ app.menuIsOpen }
      onClose={ handleOnClose }
      onOpen={ handleOnOpen }
      right
      width="300px" 
    >
      {app.sidebarLinks.map(val => (
        <Link
          to={`#${val.link}`}
          className={classNames(
            "menu-item",
            app.page === val.link ? "menu-selected" : undefined,
          )}
          key={val.link}
          onClick={() => {
            dispatch(appActions.setAppMenuIsOpen(false));
            dispatch(appActions.setAppPage(val.link));
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          { val.title }
        </Link>
      ))}
    </Menu>
  );
};

export default Sidebar;
