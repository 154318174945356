export const appReduxState = {
  menuIsOpen: false,
  page: "home",
  sidebarLinks: [
    {
      title: "Home",
      link: "home"
    }, {
      title: "What is social media?",
      link: "what-is-social-media"
    }, {
      title: "Effects of social media",
      link: "effects-of-social-media"
    }, {
      title: "Positive impacts",
      link: "positive-impacts"
    }, {
      title: "Negative impacts",
      link: "negative-impacts"
    }, {
      title: "Screen time",
      link: "screen-time"
    }, {
      title: "Alternatives",
      link: "alternatives"
    }, {
      title: "Is social media an addiction?",
      link: "is-social-media-an-addiction"
    }, {
      title: "My experience",
      link: "my-experience"
    }, {
      title: "About this site",
      link: "about-this-site"
    }
  ]
};

const app = (state = appReduxState, action) => {
  switch (action.type) {
    case "SET_APP_MENUISOPEN": return {
      ...state,
      ...{
        menuIsOpen: action.payload,
      }
    };
    case "SET_APP_PAGE": return {
      ...state,
      ...{
        page: action.payload,
      }
    };
    default: return state;
  }
};

export default app;
