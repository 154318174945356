import React from "react";

import rootReducer from "../../reducers";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { HelmetProvider } from 'react-helmet-async';

import { BrowserRouter } from 'react-router-dom';

import Container from "../Container";
import Content from "../Content";
import Footer from "../Footer";
import Header from "../Header";

const App = () => {
  
  const composeEnhancers = (
    process.env.NODE_ENV === "development" ? (
      global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) : (
      null
    )
  ) || compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        thunk
      )
    )
  );

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <Container>
            <Header />
            <Content />
            <Footer />
          </Container>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
  }

export default App;
