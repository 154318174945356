import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

// import { ResponsivePie } from '@nivo/pie'
import { Chart } from "react-google-charts";

import "./index.scss";

// import * as V from 'victory';
// import { VictoryPie } from 'victory';

// import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const ScreenTime = () => {
  
  // const data = [
  //   {quarter: 1, earnings: 13000},
  //   {quarter: 2, earnings: 16500},
  //   {quarter: 3, earnings: 14250},
  //   {quarter: 4, earnings: 19000}
  // ];

  // const data = [
  //   { name: 'Group A', value: 400 },
  //   { name: 'Group B', value: 300 },
  //   { name: 'Group C', value: 300 },
  //   { name: 'Group D', value: 200 },
  // ];

  // const handleMouseEnter = (o) => {
  //   const { dataKey } = o;
  //   // const { opacity } = this.state;

  //   // this.setState({
  //   //   opacity: { ...opacity, [dataKey]: 0.5 },
  //   // });
  // };

  // const handleMouseLeave = (o) => {
  //   const { dataKey } = o;
  //   const { opacity } = this.state;

  //   this.setState({
  //     opacity: { ...opacity, [dataKey]: 1 },
  //   });
  // };

  // const data = [
  //   {
  //     id: "Less than 1 hour",
  //     label: "Less than 1 hour",
  //     value: 8.8,
  //     color: "hsl(103, 70%, 50%)"
  //   }, {
  //     id: "1 to 2 hours",
  //     label: "1 to 2 hours",
  //     value: 24.6,
  //     color: "hsl(103, 70%, 50%)"
  //   }, {
  //     id: "3 to 4 hours",
  //     label: "3 to 4 hours",
  //     value: 36.8,
  //     color: "hsl(103, 70%, 50%)"
  //   }, {
  //     id: "4 to 5 hours",
  //     label: "4 to 5 hours",
  //     value: 12.3,
  //     color: "rgb(0, 255, 0)"
  //   }, {
  //     id: "5+ hours",
  //     label: "5+ hours",
  //     value: 17.5,
  //     color: "rga(0, 0, 0)"
  //   }
  // ];

  // const dataGoogle = [
  //   ["Task", "Daily screen time"],
  //   ["Less than 1 hour", 8.8],
  //   ["1–2 hours", 24.6],
  //   ["3–4 hours", 36.8],
  //   ["4–5 hours", 12.3],
  //   ["5+ hours", 17.5],
  // ];
  // const options = {
  //   // title: "Daily screen time",
  //   is3D: true,
  //   // sliceVisibilityThreshold: 0.0005,
  //   // backgroundColor:"red",
  //   legend: "bottom",
  //   // chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
  //   // pieSliceText: "text",
  // };

  return (
    <>
      <Helmet>
        <title>Screen time</title>
      </Helmet>
      <PageHeader title="Screen time" />

      <p>
        A Google form was sent out targeted towards years 7-11 in Dwight School London, which delved deeper into their 
        social media and screen time usage. After gathering around 59 results, there were some shocking answers. An 
        important question was finding out people's daily screen time, and the leading option was 3-4 hours, which is 
        almost normalised in today's younger generations, the second largest option was 1-2 hours, which was followed by 
        the third largest option, being 5+ hours.
      </p>

      <h2>Daily screen time</h2>
      <div className="pie-chart-container google">
        <Chart
          chartType="PieChart"
          data={[
            ["Task", "Daily screen time"],
            ["Less than 1 hour", 8.8],
            ["1–2 hours", 24.6],
            ["3–4 hours", 36.8],
            ["4–5 hours", 12.3],
            ["5+ hours", 17.5],
          ]}
          options={{
            // backgroundColor:"red",
            // chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
            is3D: true,
            legend: "bottom",
            // pieSliceText: "text",
            // sliceVisibilityThreshold: 0.0005,
            // title: "Daily screen time",
          }}
          width={"100%"}
          height={"300px"}
          // onLoad={() => { console.log("LOAD"); }}
        />
      </div>
      <p>
        Several graphs were formed from the answers of this online questionnaire, one of the graphs was created 
        off of how much the screen time was from the students in Dwight School London, almost 75% of the respondents 
        agreed to social media being the leading cause of their screen time, overall showing the control and effect 
        that social media had on them.
      </p>

      <h2>Is the majority of your screen time from these social media apps?</h2>
      <h3>59 responses</h3>
      <div className="pie-chart-container google">
        <Chart
          chartType="PieChart"
          data={[
            ["Task", "Is the majority of your screen time from these social media apps?"],
            ["No", 25.4],
            ["Yes", 74.6],
          ]}
          options={{
            // backgroundColor:"red",
            // chartArea: { left: 15, top: 15, right: 0, bottom: 0 },
            is3D: true,
            legend: "bottom",
            // pieSliceText: "text",
            // sliceVisibilityThreshold: 0.0005,
            // title: "Daily screen time",
          }}
          width={"100%"}
          height={"300px"}
          // onLoad={() => { console.log("LOAD"); }}
        />
      </div>
      <p>
        Almost 90% of the students that replied, owned and used some sort of social media app. 
        Followed by 75% of all students using social media platforms more than any other app or site.
      </p>



      
    </>
  );
};

export default ScreenTime;
