import React from "react";
import PropTypes from "prop-types";

const PageHeader = ({ title }) => (
  <h1 className="pageheader">
    { title }
  </h1>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
