import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

const MyExperience = () => (
  <>
    <Helmet>
      <title>My experience</title>
    </Helmet>
    <PageHeader title="My experience" />
    <p>
      The first day I decreased my social media usage considerably was on November 15th, which was the start of my 
      two-week journey to shorten my usage, as I was a victim of addiction. I had set a plan to only use whatsapp when 
      necessary, (to call my mum to pick me up) and so on. My previous several hours of scrolling on youtube and texting 
      people was severely diminished. 
    </p>
    <p>
      The first few day’s were particularly a struggle, as all of my friends were online and texting and I wanted to be 
      a part of it. I also suffered from phantom vibration syndrome, which was when I thought my phone was vibrating or 
      notified me, when it was not. I also stopped using my phone when I was about to or getting ready for bed. This was 
      a habit I had created a few months ago which made my sleep schedule inconsistent and rough. After the first week, 
      I felt like I had more energy, and also wasn’t tired during school, which was the case multiple times before.  
    </p>
    <p>
      After about a week of time, I felt more productive, and I was more focused. I spent more time with my family and went 
      to bed earlier. I also went for runs in the afternoon to get some fresh air. However every so often I would get a 
      severe urge to check my phone for any incoming messages or information. Despite this, I tried to keep my media usage 
      to 20 minutes per day.
    </p>
    <p>
      As I went to bed earlier, I started having breakfast, which was a meal I skipped constantly due to not having enough 
      time before school. I texted less on group chats, and spent more time being productive. As I approached the second 
      week I started talking to people on the bus on the way home, instead of staying quiet or going on my phone, which was 
      what I usually did to use the time up till I got home.
    </p>
    <p>
      During the second week, I got used to using social media less and started watching more movies, which 
      was something I rarely did, instead of playing video games or going on my phone. I also didn’t think 
      of this as an experiment anymore, and it was becoming more normal.
    </p>
  </>
);

export default MyExperience;
