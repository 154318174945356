import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import AboutThisSite from "../pages/AboutThisSite";
import Alternatives from "../pages/Alternatives";
import EffectsOfSocialMedia from "../pages/EffectsOfSocialMedia";
import Home from "../pages/Home";
import IsSocialMediaAnAddiction from "../pages/IsSocialMediaAnAddiction";
import NegativeImpacts from "../pages/NegativeImpacts";
import MyExperience from "../pages/MyExperience";
import PositiveImpacts from "../pages/PositiveImpacts";
import ScreenTime from "../pages/ScreenTime";
import WhatIsSocialMedia from "../pages/WhatIsSocialMedia";

import "./index.scss";

const Content = () => {  
  const { app } = useSelector(state => state);
  const pageHash = window.location.hash;
  return (
    <div
      className={classNames(
        "content",
        app.page !== "" ? app.page : "home"
      )}
    >
      {(() => {
        switch (pageHash) {
          case "#about-this-site": return <AboutThisSite />
          case "#alternatives": return <Alternatives />
          case "#effects-of-social-media": return <EffectsOfSocialMedia />;
          case "#is-social-media-an-addiction": return <IsSocialMediaAnAddiction />;
          case "#negative-impacts": return <NegativeImpacts />;
          case "#my-experience": return <MyExperience />;
          case "#positive-impacts": return <PositiveImpacts />;
          case "#screen-time": return <ScreenTime />;
          case "#what-is-social-media": return <WhatIsSocialMedia />;
          default: return <Home />;
        }
      })()}
    </div>
  );
};

export default Content;
