import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import allActions from "../../actions";

import Sidebar from "../Sidebar";

import "./index.scss";

const Container = ({ children }) => {

  const { appActions } = { ...allActions };
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(appActions.setAppPage(window.location.hash.replace(/#/g, "")));
    return () => null;
  }, [ dispatch, appActions ]);

  return (
    <div className="outer-container">
      <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
      <div id="page-wrap">
        { children }
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
};

export default Container;
