const setLanguageLanguage = data => ({
  payload: data,
  type: "SET_LANGUAGE_LANGUAGE",
});

const exportedLanguageObject = {
  setLanguageLanguage,
};

export default exportedLanguageObject;
