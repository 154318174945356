import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import image from "../../../assets/pages/is-social-media-an-addiction-01.jpg";

const IsSocialMediaAnAddiction = () => (
  <>
    <Helmet>
      <title>Is social media an addiction?</title>
    </Helmet>
    <PageHeader title="Is social media an addiction?" />
    <p>
      Social media has become a relevant problem in the younger generations and teenagers often find themselves 
      scrolling for many hours a day. When using social media, the body creates dopamine, which is a chemical that makes you 
      feel good. Dopamine is stimulated mainly by unpredictability and small quantities of information. Dopamine was thought 
      to be a pleasure chemical, but actually creates a strong feeling of desire, need and want. In many cases, the major 
      effect of dopamine being consumed makes social media harder to resist than alcohol or smoking.
    </p>
    <p>
      Another chemical, called ‘oxytocin’ is released when you kiss or hug, this chemical promotes positive 
      emotions. However, oxytocin levels can rise to over 13%, in just 10 minutes of using social media. This huge 
      increase in oxytocin consumption is equivalent to the oxytocin outlet of many people on their wedding day. When 
      spending periods of time using social media, the brain rewards the body by sending small levels of dopamine, due 
      to watching pleasurable content.
    </p>
    <div className="image-container">
      <img
        src={image} 
        width="100%"
        alt="Effects of social media"
      />
    </div>
  </>
);

export default IsSocialMediaAnAddiction;
