const setAppMenuIsOpen = data => ({
  payload: data,
  type: "SET_APP_MENUISOPEN",
});
const setAppPage = data => ({
  payload: data,
  type: "SET_APP_PAGE",
});

const exportedAppObject = {
  setAppMenuIsOpen,
  setAppPage,
};

export default exportedAppObject;
