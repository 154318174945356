import React from "react";

import { Helmet } from "react-helmet-async";

import PageHeader from "../../PageHeader";

import image from "../../../assets/pages/negative-impacts.jpg";

const NegativeImpacts = () => (
  <>
    <Helmet>
      <title>Negative impacts</title>
    </Helmet>
    <PageHeader title="Negative impacts" />
    <p>
      Increased social media usage can also lead to some devastating side effects, which can inhibit or or hinder your 
      sleep patterns, causing your mood to change the next day. Furthermore, social media can also create a false sense 
      of meaning and gives many users FOMO (fear of missing out), which can cause a deceiving sense of reality, and 
      can make younger users of media platforms question why their life isn’t as intriguing or fascinating as their desired 
      individual, which vastly disrupts their feelings and can also lead to negative emotions. Social anxiety disorder 
      can be developed from the overuse of social media, which can lead you to constantly compare yourself to others, 
      and worry about other people’s criticism, therefore affecting your own self esteem. 
    </p>
    <p>
      Increased use of social media can have a vast affect on your sleep patterns and quality. While consuming more 
      purposely addictive social media content, sleep patterns can become irregular, which can lead to a plummet in 
      productivity. This is influenced primarily due to using your phone at night, as 5 minutes can turn into an hour, 
      all spent mindlessly scrolling through media you won’t remember in 2 weeks time. Social media platforms like 
      TikTok rely on ads to make most of their money, which relies on you watching constant short videos that ruin your  
      attention span and being able to focus in the long run.
    </p>
    <p>
      How can you check if you are addicted?
    </p>
    <p>
      Do you remember the last time you spent a full day without checking any or using minimal use of social media platforms?
    </p>
    <p>
      Would you feel upset or rejected if someone unfollowed you?
    </p>
    <div className="image-container">
      <img
        src={image} 
        width="100%"
        alt="Effects of social media"
      />
    </div>
  </>
);

export default NegativeImpacts;
